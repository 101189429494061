import firebase from 'firebase/app'

const getDownloadUrl = async (path: string) => {
  try {
    const downloadUrl = await firebase
      .storage()
      .ref(path)
      .getDownloadURL()
    return downloadUrl
  } catch (ex) {
    return null
  }
}

const uploadFile = async (path: string, data: Blob | Uint8Array | ArrayBuffer) => {
  try {
    await firebase.storage().ref(path).put(data)
  } catch (ex) {
    throw ex
  }
}

const listFiles = async (path: string) => {
  const files = await firebase.storage().ref(path).list()
  return files.items.map((item) => item.fullPath)
}

const deleteFromUrl = async (url: string) => {
  try {
    await firebase.storage().refFromURL(url).delete()
  } catch (ex) {
    throw ex
  }
}

export { getDownloadUrl, uploadFile, listFiles, deleteFromUrl }