


















































import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Loading from '@/components/Loading.vue'
import Content from '@/components/Content.vue'
import { listFiles, getDownloadUrl } from '@/services/Storage'

@Component({
  components: {
    Header,
    Content,
    Loading
  }
})
export default class Press extends Vue {
  loading: boolean = true
  files: Array<{ name: string; url: string }> = []

  async created() {
    const paths = await listFiles('/content/press')

    this.files = await Promise.all(paths.map((path) => this.populateFile(path)))

    this.loading = false
  }

  async populateFile(path: string) {
    const url = await getDownloadUrl(path)

    const pathParts = path.split('/')
    return {
      name: pathParts[pathParts.length - 1],
      url
    }
  }
}
