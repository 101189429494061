














































































import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Content from '@/components/Content.vue'

@Component({
  components: {
    Header,
    Content
  }
})
export default class Article extends Vue {
  article: 'ooen' | 'tips' | 'atv'

  get title(): string {
    switch (this.article) {
      case 'ooen': {
        return 'OÖN Beitrag vom 1. September 2020'
      }
      case 'tips': {
        return 'Tips Beiträge'
      }
      case 'atv': {
        return 'ATV Beitrag vom 3. August 2020'
      }
    }
  }

  created() {
    this.article = this.$route.params.articleId as any
    document.title = `${this.title} – meinSCHWEIN`
  }
}
